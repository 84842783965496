import React from 'react';

const Features = () => {
    return (
        <div id='feature' className='py-24 bg-slate-100'>
            <div className='container mx-auto px-5 md:px-0'>
                <div className='flex flex-col gap-6 text-left'>
                    <h3 className='text-3xl font-bold text-sky-800'>FEATURES</h3>
                    <div className='grid grid-cols-12 gap-8'>
                        <div className='flex flex-col gap-2 col-span-12 md:col-span-6 bg-slate-100 rounded-md p-3 shadow-md'>
                            <h4 className='text-lg font-bold uppercase'>send messages from your page's inbox</h4>
                            <p>Imagine delivering promotions to those who've already shown interest in your business. With toolAre, you can send laser-targeted messages to everyone in your Facebook page's inbox.</p>
                        </div>
                        <div className='flex flex-col gap-2 col-span-12 md:col-span-6 bg-slate-100 rounded-md p-3 shadow-md'>
                            <h4 className='text-lg font-bold'>Label users directly from your posts, not just from your inbox.</h4>
                            <p>Easily categorize your customers using labels, organizing leads by product, interest, service, or any tag that suits your business. With ToolAre, you can label multiple users simultaneously.</p>
                        </div>
                        {/* <div className='flex flex-col gap-2 col-span-6 bg-slate-100 rounded-md p-3 shadow-md'>
                            <h4 className='text-lg font-bold'>Handling lots of post comments? toolAre saves time.</h4>
                            <p>Many users have the same question. With ToolAre, reply to them all with just one click: select, write, and send. It's that simple!</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Features;