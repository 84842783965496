import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='container mx-auto text-left px-5 md:px-0'>
      <h1 className='text-3xl font-bold'>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> 29/06/2024</p>
      <br></br>
      <br></br>
      <h2 className='text-xl font-bold'>1. Introduction</h2>
      <p>Welcome to facebook page bulk message send. We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Facebook bulk messaging service. By using our service, you agree to the terms of this Privacy Policy.</p>
      
      <h2 className='text-xl font-bold'>2. Information We Collect</h2>
      <h3 className='text-base text-slate-500 font-bold'>2.1 Personal Information</h3>
      <p>We may collect personal information that you voluntarily provide to us, such as your name, email address, and contact details when you register or contact us for support.</p>
      
      <h3 className='text-base text-slate-500 font-bold'>2.2 Facebook Data</h3>
      <p>To provide our services, we may collect certain data from your Facebook account with your consent. This includes access to your Facebook Page inbox, the ability to send messages on your behalf, and other necessary permissions.</p>
      
      <h3 className='text-base text-slate-500 font-bold'>2.3 Usage Data</h3>
      <p>We may collect information about how you interact with our service, such as the frequency and duration of your usage, messages sent, and other related statistics.</p>
      
      <h2 className='text-xl font-bold'>3. How We Use Your Information</h2>
      <h3 className='text-base text-slate-500 font-bold'>3.1 To Provide and Improve Our Service</h3>
      <p>We use the information we collect to operate, maintain, and improve our service. This includes using your Facebook data to send bulk messages as requested by you.</p>
      
      <h3 className='text-base text-slate-500 font-bold'>3.2 To Communicate with You</h3>
      <p>We may use your personal information to contact you with updates, promotional materials, and other information that may be of interest to you.</p>
      
      <h3 className='text-base text-slate-500 font-bold'>3.3 To Ensure Compliance and Security</h3>
      <p>We may use your information to monitor and ensure compliance with our terms of service and applicable laws, and to enhance the security of our service.</p>
      
      <h2 className='text-xl font-bold'>4. How We Share Your Information</h2>
      <h3 className='text-base text-slate-500 font-bold'>4.1 Third-Party Service Providers</h3>
      <p>We may share your information with third-party service providers who assist us in providing our services. These providers are contractually obligated to maintain the confidentiality of your information.</p>
      
      <h3 className='text-base text-slate-500 font-bold'>4.2 Legal Requirements</h3>
      <p>We may disclose your information if required to do so by law or in response to valid requests by public authorities.</p>
      
      <h2 className='text-xl font-bold'>5. Data Security</h2>
      <p>We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or method of electronic storage is completely secure.</p>
      
      <h2 className='text-xl font-bold'>6. Your Choices</h2>
      <p>You can control the information we collect and how it is used by adjusting your Facebook account settings and the permissions you grant to our service.</p>
      
      <h2 className='text-xl font-bold'>7. Children's Privacy</h2>
      <p>Our service is not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13.</p>
      
      <h2 className='text-xl font-bold'>8. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. Your continued use of the service after any changes constitutes your acceptance of the new Privacy Policy.</p>
      
      <h2 className='text-xl font-bold'>9. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at info@toolare.com</p>
      <br></br>
      <br></br>
      <br></br>
      <p><strong>toolAre</strong></p>
      <p>info@toolare.com</p>
    </div>
  );
};

export default PrivacyPolicy;
