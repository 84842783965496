import React from 'react';
import './styleUi.css';
import LogoWithChar from '../LogoWithChar/LogoWithChar';

const InboxUi = () => {
    return (
        <div className='container mx-auto'>
            <div className='grid grid-cols-3 gap-3'>
                <div className='col-span-1 flex flex-col'>
                    <div className='flex flex-col gap-3'>
                        <div className='flex flex-col gap-3'>
                            <div className='flex items-center gap-3'>
                                <img src={require('./img/logo.png')} />
                                <h3>Dorqar Soft</h3>
                            </div>
                            <div className='flex flex-col'>
                                <div className='srcWrap grid grid-cols-12'>
                                    <div className='src col-span-9 flex flex-col relative ' >
                                        <i class="fa-solid fa-magnifying-glass self-center text-xs text-gray-400 absolute top-[50%] left-[10px]" style={{ transform: 'translateY(-50%)' }}></i>
                                        <input placeholder='search' type='text' className='pl-8 py-1 w-full focus:outline-none' />
                                    </div>
                                    <button className='srcBtn px-2 col-span-3 bg-sky-700 text-white font-bold'>Search</button>
                                </div>
                            </div>
                            <div className="grid grid-cols-[auto,100px] bg-blue-100 p-2">
                                <div class="flex gap-4">
                                    <input type='checkbox' />
                                    <span>Select 60 conversations</span>
                                </div>
                                <div class="self-end flex flex-row gap-4 justify-end">
                                    <span className='font-bold'>Filter</span>
                                    <select className='bg-transparent'>
                                        <option></option>
                                        <option>A</option>
                                        <option>B</option>
                                        <option>C</option>
                                        <option>D</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div className='flex flex-col'>
                        <h2 className="text-3xl font-bold text-left">Inbox</h2>
                        <div className='flex flex-col'>
                            <div class="flex flex-col gap-4 pt-4">
                                <h3 className='text-start font-bold'>Unread</h3>
                                <div className='flex flex-col gap-3'>
                                    <div className="grid grid-cols-[11px,40px,auto] gap-4 relative">
                                        <input type='checkbox' /> <img className='self-center' src={require('./img/avater.png')} />
                                        <div className="flex flex-col items-start">
                                            <p>Josiah Zayner</p>
                                            <p className='font-bold'>Hi there!</p>
                                            <p className="text-gray-600">How are you today?</p>
                                        </div>
                                        <span class="absolute top-0 right-0 text-sm text-gray-500">9.56 AM</span>
                                    </div>

                                    <div className="grid grid-cols-[11px,40px,auto] gap-4 relative">
                                        <input type='checkbox' /> <img className='self-center' src={require('./img/avater.png')} />
                                        <div className="flex flex-col items-start">
                                            <p>Josiah Zayner</p>
                                            <p className='font-bold'>Hi there!</p>
                                            <p className="text-gray-600">How are you today?</p>
                                        </div>
                                        <span class="absolute top-0 right-0 text-sm text-gray-500">9.56 AM</span>
                                    </div>

                                    <div className="grid grid-cols-[11px,40px,auto] gap-4 relative">
                                        <input type='checkbox' /> <img className='self-center' src={require('./img/avater.png')} />
                                        <div className="flex flex-col items-start">
                                            <p>Josiah Zayner</p>
                                            <p className='font-bold'>Hi there!</p>
                                            <p className="text-gray-600">How are you today?</p>
                                        </div>
                                        <span class="absolute top-0 right-0 text-sm text-gray-500">9.56 AM</span>
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col gap-4 pt-4">
                                <h3 className='text-start font-bold'>Other</h3>
                                <div className='flex flex-col gap-3'>
                                    <div className="grid grid-cols-[11px,40px,auto] gap-4 relative">
                                        <input type='checkbox' /> <img className='self-center' src={require('./img/avater.png')} />
                                        <div className="flex flex-col items-start">
                                            <p>Josiah Zayner</p>
                                            <p className='font-bold'>Hi there!</p>
                                            <p className="text-gray-600">How are you today?</p>
                                        </div>
                                        <span class="absolute top-0 right-0 text-sm text-gray-500">9.56 AM</span>
                                    </div>

                                    <div className="grid grid-cols-[11px,40px,auto] gap-4 relative">
                                        <input type='checkbox' /> <img className='self-center' src={require('./img/avater.png')} />
                                        <div className="flex flex-col items-start">
                                            <p>Josiah Zayner</p>
                                            <p className='font-bold'>Hi there!</p>
                                            <p className="text-gray-600">How are you today?</p>
                                        </div>
                                        <span class="absolute top-0 right-0 text-sm text-gray-500">9.56 AM</span>
                                    </div>

                                    <div className="grid grid-cols-[11px,40px,auto] gap-4 relative">
                                        <input type='checkbox' /> <img className='self-center' src={require('./img/avater.png')} />
                                        <div className="flex flex-col items-start">
                                            <p>Josiah Zayner</p>
                                            <p className='font-bold'>Hi there!</p>
                                            <p className="text-gray-600">How are you today?</p>
                                        </div>
                                        <span class="absolute top-0 right-0 text-sm text-gray-500">9.56 AM</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-span-2 bg-gray-300 flex flex-col'>
                    <div className="bg-gray-200 p-5 flex flex-col gap-6 shadow-sm">
                        <h2 className="text-3xl font-bold text-left">NEW BULK MESSAGE</h2>
                        <ul className="flex flex-col items-start gap-4">
                            <li>Select labesl to choose who to send a message</li>
                            <li>Use “all recipients” to select everyone on your pages inbox</li>
                            <li>Use “no label” to select the peoples with no labels</li>
                            <li>You can also use labels to exclude people</li>
                        </ul>
                        <div className="flex flex-col gap-4">
                            <h4 class="text-left font-bold">Labels</h4>
                            <div class="flex flex-row gap-6">
                                <button class="bg-gray-300 p-1.5 px-2 rounded-md">all recipients<span class="bg-gray-400 p-0 px-1 rounded-md ml-4 text-xs">999</span></button>
                                <button class="bg-gray-300 p-1.5 px-2 rounded-md">all recipients<span class="bg-gray-400 p-0 px-1 rounded-md ml-4 text-xs">999</span></button>
                                <button class="bg-gray-300 p-1.5 px-2 rounded-md">all recipients<span class="bg-gray-400 p-0 px-1 rounded-md ml-4 text-xs">999</span></button>
                                <button class="bg-gray-300 p-1.5 px-2 rounded-md">all recipients<span class="bg-gray-400 p-0 px-1 rounded-md ml-4 text-xs">999</span></button>
                            </div>
                        </div>
                    </div>
                    <div className='relative h-full'>
                        <div>
                            <ul class="flex flex-col text-left gap-6 p-6">
                                <li className='relative'>
                                    <p class="text-center text-xs font-bold pb-5">Apr 29, 2023, 10:14 PM</p>
                                    <div class="pl-14 flex">
                                        <span class="bg-gray-200 p-1 px-2 rounded-lg text-sm" >heloow</span>
                                    </div>
                                    <div class="absolute left-0 bottom-0">
                                        <LogoWithChar name="shafayat hossain" w="30" h="30" />
                                    </div>
                                </li>

                                <li className='relative'>
                                    <p class="text-center text-xs font-bold pb-5">Apr 29, 2023, 10:14 PM</p>
                                    <div class="pl-14 flex">
                                        <span class="bg-gray-200 p-1 px-2 rounded-lg text-sm" >heloow</span>
                                    </div>
                                    <div class="absolute left-0 bottom-0">
                                        <LogoWithChar name="shafayat hossain" w="30" h="30" />
                                    </div>
                                </li>
                                <li className='relative'>
                                    <p class="text-center text-xs font-bold pb-5">Apr 29, 2023, 10:14 PM</p>
                                    <div class="pl-14 flex">
                                        <span class="bg-gray-200 p-1 px-2 rounded-lg text-sm" >By applying these classes to the desired element, you can achieve the same positioning effect as the CSS code provided.</span>
                                    </div>
                                    <div class="absolute left-0 bottom-0">
                                        <LogoWithChar name="shafayat hossain" w="30" h="30" />
                                    </div>
                                </li>
                                
                                <li className='relative'>
                                    <div class="pl-14 flex">
                                        <span class="bg-gray-200 p-1 px-2 rounded-lg text-sm" >By applying <br></br>these classes to the desired element, you can achieve the same positioning effect as the CSS code provided.</span>
                                    </div>
                                    <div class="absolute left-0 bottom-0">
                                        <LogoWithChar name="shafayat hossain" w="30" h="30" />
                                    </div>
                                </li>
{/*                                 
                                <li className='relative'>
                                    <div class="pl-14 flex text-right justify-end">
                                        <span class="bg-gray-400 p-1 px-2 rounded-lg text-sm" >By applying <br></br>these classes to the desired element, you can achieve the same positioning effect as the CSS code provided.</span>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                        <div className='absolute bottom-0 w-full grid grid-cols-6 p-3'>
                            <input type='text' className='col-span-5 py-1 focus:border-transparent' />
                            <button className='col-span-1 bg-slate-900 text-white font-bold py-1 text-xs'>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InboxUi;