
export const getAgoTime = (d) => {
    const inputDate = d;
    const date = new Date(inputDate);
    const currentTime = new Date();

    const timeDifference = currentTime.getTime() - date.getTime();
    const secondsAgo = Math.floor(timeDifference / 1000);

    let formattedTime = '';
    if (secondsAgo < 60) {
        formattedTime = `${secondsAgo}s`;
    } else if (secondsAgo < 3600) {
        const minutesAgo = Math.floor(secondsAgo / 60);
        formattedTime = `${minutesAgo}m`;
    } else if (secondsAgo < 86400) {
        const hoursAgo = Math.floor(secondsAgo / 3600);
        formattedTime = `${hoursAgo}h`;
    } else if (secondsAgo < 604800) {
        const daysAgo = Math.floor(secondsAgo / 86400);
        formattedTime = `${daysAgo}d`;
    } 
    else if (secondsAgo < 31536000) {
        const weeksAgo = Math.floor(secondsAgo / 604800);
        formattedTime = `${weeksAgo}w`;
    } 
    // else if (secondsAgo < 2592000) {
    //     const weeksAgo = Math.floor(secondsAgo / 604800);
    //     formattedTime = `${weeksAgo} weeks ago`;
    // } 
    // else if (secondsAgo < 31536000) {
    //     const monthsAgo = Math.floor(secondsAgo / 2592000);
    //     formattedTime = `${monthsAgo} months ago`;
    // } 
    else {
        const yearsAgo = Math.floor(secondsAgo / 31536000);
        formattedTime = `${yearsAgo}y`;
    }

    return formattedTime;

}

export const limit_content=(str, limit)=>{
    let strFormat = ""; 
    if(str.length > limit){
        strFormat = str.substring(0, limit) + '...'
    }else {
        strFormat = str; 
    }

    return strFormat; 
}