import React from 'react';

const Popup = ({title="title", message="message", callBackPopup}) => {
    const closePopup = () => {
        callBackPopup(false);
    }
    return (
        <div className='fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-full flex items-center justify-center'>
            
            <div className="alert alert-warning z-30 w-96 py-12 grid grid-cols-12">
                <div className='col-span-2'>
                <svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.94024 35.0004H35.0602C38.1402 35.0004 40.0602 31.6604 38.5202 29.0004L23.4602 2.98035C21.9202 0.320352 18.0802 0.320352 16.5402 2.98035L1.48024 29.0004C-0.0597576 31.6604 1.86024 35.0004 4.94024 35.0004ZM20.0002 21.0004C18.9002 21.0004 18.0002 20.1004 18.0002 19.0004V15.0004C18.0002 13.9004 18.9002 13.0004 20.0002 13.0004C21.1002 13.0004 22.0002 13.9004 22.0002 15.0004V19.0004C22.0002 20.1004 21.1002 21.0004 20.0002 21.0004ZM22.0002 29.0004H18.0002V25.0004H22.0002V29.0004Z" fill="#F98600" />
                </svg>
                </div>
                <div className="col-span-10 w-full flex flex-col text-left">
                   {title.length > 0 && <span className='font-bold'>{title}</span>} 
                  {message.length > 0  &&  <span className="text-content2">{message}</span>  } 
                </div>
            </div>
            <button onClick={closePopup} className='absolute top-5 right-5 text-slate-800 font-bold z-40 bg-white shadow-md p-2 rounded-lg'>Close</button>
            <div className='bg-gray-700 opacity-50 absolute top-0 left-0 w-full h-full z-20'></div>
        </div>
    );
};

export default Popup;