import React from 'react';

const TermsOfService = () => {
  return (
    <div className='container mx-auto text-left px-5 md:px-0'>
      <h1 className='text-3xl font-bold'>Terms of Service</h1>
      <p><strong>Effective Date:</strong> 29/06/2024</p>
      <br></br>
      <br></br>
       <h2 className='text-xl font-bold'>1. Acceptance of Terms</h2>
      <p>By accessing and using facebook page bulk message send, you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use our Service.</p>
      
       <h2 className='text-xl font-bold'>2. Description of Service</h2>
      <p>facebook page bulk message send provides a tool for sending bulk messages from your Facebook Page inbox. Our Service allows you to communicate with multiple users efficiently.</p>
      
       <h2 className='text-xl font-bold'>3. User Accounts</h2>
      <p>To use our Service, you may be required to create an account and provide certain information. You agree to provide accurate and complete information and to keep this information up to date. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.</p>
      
       <h2 className='text-xl font-bold'>4. Use of Service</h2>
      <p>You agree to use the Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service:</p>
      <ul>
        <li>To violate any local, state, national, or international law or regulation.</li>
        <li>To infringe on the rights of others, including privacy and intellectual property rights.</li>
        <li>To send spam, phishing, or other unsolicited messages.</li>
        <li>To transmit any harmful or malicious content, such as viruses or malware.</li>
      </ul>
      
       <h2 className='text-xl font-bold'>5. Intellectual Property</h2>
      <p>All content, features, and functionality of the Service, including text, graphics, logos, and software, are the exclusive property of toolAre and are protected by copyright, trademark, and other intellectual property laws. You may not use any of our intellectual property without our prior written consent.</p>
      
       <h2 className='text-xl font-bold'>6. Termination</h2>
      <p>We reserve the right to terminate or suspend your account and access to the Service at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users of the Service, us, or third parties, or for any other reason.</p>
      
       <h2 className='text-xl font-bold'>7. Disclaimer of Warranties</h2>
      <p>The Service is provided "as is" and "as available," without any warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not warrant that the Service will be uninterrupted, error-free, or free of viruses or other harmful components.</p>
      
       <h2 className='text-xl font-bold'>8. Limitation of Liability</h2>
      <p>To the fullest extent permitted by applicable law, toolAre shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the Service; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from the Service; (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Service by any third party; (e) any errors or omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or (f) the defamatory, offensive, or illegal conduct of any third party.</p>
      
       <h2 className='text-xl font-bold'>9. Indemnification</h2>
      <p>You agree to indemnify, defend, and hold harmless toolAre, its officers, directors, employees, agents, and affiliates, from and against any and all claims, liabilities, damages, losses, costs, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use of the Service, your violation of these Terms, or your violation of any rights of another.</p>

       <h2 className='text-xl font-bold'>10. Changes to Terms</h2>
      <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our website. Your continued use of the Service after any changes constitutes your acceptance of the new Terms.</p>
      
       <h2 className='text-xl font-bold'>11. Contact Us</h2>
      <p>If you have any questions or concerns about these Terms, please contact us at info@toolare.com.</p>
      <br></br>
      <br></br>
      <p><strong>toolAre</strong></p>
      <p>info@toolare.com</p>
    </div>
  );
};

export default TermsOfService;
