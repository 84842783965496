import React, { useEffect, useState } from 'react';

const LogoWithChar = ({ name, w="40",h="40" }) => {
    const [getChar, setChar] = useState("");

    const getCharFunc = () => {
        const strArr = name.split(" ");
        const char = strArr.map((d, i) => i < 2 && d[0]);
        setChar(char);
    }
    useEffect(() => {
        getCharFunc();
    }, [])
    return (
        <div className="flex items-center justify-center bg-slate-400 rounded-full text-xs font-bold"
        style={{width: `${w}px`, height: `${h}px`}}
        >
            <span>{getChar}</span>
        </div>
    );
};

export default LogoWithChar;