import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../../Component/Navbar/Navbar';
import Footer from '../../Component/Footer/Footer';
import { pageContextManager } from '../../App';
import { Link } from 'react-router-dom';

const SendingPage = () => {
    const [count, setCount] = useState(0);
    const [getPageList, setPageList, getPageInfo, setPageInfo, selectedConversation, setSelectedConversation] = useContext(pageContextManager);



    //   const sendMassageFunc = () => {

    //     window.FB.api(
    //       `/me/messages`,
    //       'POST',
    //       {
    //         recipient: { "id": "2866088973433440" },
    //         message: { "text": "Back To The Sunnah..." },
    //         messaging_type: 'MESSAGE_TAG',
    //         tag: 'CONFIRMED_EVENT_UPDATE',
    //         access_token: `${getPageInfo.access_token}`
    //       },
    //       function (response) {
    //         console.log(response);
    //       }
    //     );

    //   }

    const sendMessages = async () => {
        // const recipientId = "2866088973433440";
        const messageText = selectedConversation.messageText;
        const accessToken = getPageInfo.access_token;

        console.log('selectedConversation', selectedConversation);

        for (let i = 0; i < selectedConversation.conversation.length; i++) {
            debugger; 
            const recipientId = selectedConversation.conversation[i].participants.data[0].id;

            console.log(`Sending message ${i + 1} to ${recipientId}`);
            setCount(cnt => cnt + 1);

              try {
                await new Promise((resolve, reject) => {
                  window.FB.api(
                    `/me/messages`,
                    'POST',
                    {
                      recipient: { id: recipientId },
                      message: { text: messageText },
                      messaging_type: 'MESSAGE_TAG',
                      tag: 'CONFIRMED_EVENT_UPDATE',
                      access_token: accessToken
                    },
                    (response) => {
                      if (response && !response.error) {
                        console.log(`Message ${i + 1} sent successfully`);
                        setCount(cnt=> cnt + 1);
                        resolve();
                      } else {
                        setCount(cnt=> cnt + 1);
                        console.error(`Error sending message ${i + 1}`, response.error);
                        reject(response.error);
                      }
                    }
                  );
                });
              } catch (error) {
                console.error(`Failed to send message ${i + 1}`, error);
                // break; // Exit loop on error
              }

            // Delay between each request to avoid hitting rate limits
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay
        }
    }


    useEffect(() => {
        Object.keys(selectedConversation).length > 0 && sendMessages();
    }, [selectedConversation]);

    return (
        <>
            {
                console.log('count', count)
            }
            <div className='overflow-hidden w-full'>
                <div className='bg-sky-600 w-full h-[570px] absolute -top-11 left-0 z-20 skew-y-2'></div>
                <div className='bg-sky-700 w-full h-[570px] absolute -top-5 left-0 z-10 skew-y-2'></div>
                <div className='bg-sky-800 w-full h-screen absolute top-0 left-0 z-0'></div>
                {/* <div className='bg-sky-600 w-full h-[540px] absolute -top-11 left-0 z-0 skew-y-2'></div> */}
                <Navbar />
                <div className='divFLScreen flex flex-col justify-center w-full z-20 relative'>
                    <div className='container mx-auto'>
                        <div className='flex flex-col'>
                            <div className='mx-auto flex flex-col justify-center bg-white w-96 h-40 rounded-md p-3 items-center shadow-lg'>
                                {
                                    count === selectedConversation?.conversation?.length ?
                                        <div className='flex flex-col gap-4'>
                                            <h3 className='text-2xl font-bold'>All Messages Sent</h3>
                                            <Link to="/page-list" className='btn btn-primary'>Go To Page List</Link>
                                        </div>
                                        :
                                        <div className='flex flex-col gap-2'>
                                            <p>Sending Message <b>{count}/{selectedConversation?.conversation?.length}</b></p>
                                            <div className="progress progress-indeterminate self-center"></div>
                                            <p>{ }</p>
                                        </div>
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* <Footer/> */}
        </>

    );
};

export default SendingPage;