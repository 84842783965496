import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='relative z-20'>
            <footer class="bg-white w-full">
                <div class="w-full max-w-screen-xl mx-auto pt-6 pb-4">
                    <div className="container mx-auto  px-5 md:px-0">
                        <div class="sm:flex sm:items-center sm:justify-between">
                            <a href="https://flowbite.com/" class="flex justify-center md:justify-start items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                                <span class="self-center text-2xl text-sky-600 whitespace-nowrap dark:text-white font-bold">toolAre</span>
                            </a>
                            <ul class="flex flex-wrap justify-center md:justify-end items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                                <li>
                                    <a href="#" class="hover:underline me-4 md:me-6">About</a>
                                </li>
                                <li>
                                    <Link to={'/privacy-policy'} href="#" class="hover:underline me-4 md:me-6">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/tos" class="hover:underline me-4 md:me-6">Term Of Service</Link>
                                </li>
                                <li>
                                    <a href="#" class="hover:underline">Contact</a>
                                </li>
                            </ul>
                        </div>
                        <hr class="my-4 border-gray-200 sm:mx-auto dark:border-gray-700" />
                        <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="https://toolare.com/" class="hover:underline">toolAre</a>. All Rights Reserved.</span>
                    </div>
                </div>
            </footer>


        </div>
    );
};

export default Footer;